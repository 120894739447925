import React from 'react';

// Set up PDF.js worker
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => {
    const videos = {
        '1. ISRAEL:_ INTERNAL POLITICS CRISIS': {
            'text': `LEDE: In a bid to gain more decision making power in Israel’s war with Hamas, national security minister Itamar Ben Gvir refused to vote with the coalition on a Shas-fronted bill. But the breakdown between parties afterwards may have been more than he bargained for. More in this report by Emma Siegel: 
CGS: NONE 
ONCE AGAIN, IT LOOKS AS THOUGH ISRAEL’S GOVERNMENT IS IN CRISIS. 
ON MONDAY NIGHT PUBLIC SECURITY MINISTER ITAMAR BEN GVIR REFUSED TO VOTE IN FAVOUR OF A BILL PUT FORWARD BY THE ULTRA-ORTHODOX  SHAS PARTY. THE HARD-LINE BEN GVIR  HAS BEEN ANGLING FOR MONTHS TO BE INCLUDED IN ISRAEL’S NOW-DEFUNCT WAR CABINET, AND THIS LATEST MOVE IS A TACTIC TO BE ALLOWED HAVE GREATER SAY IN ISRAEL’S WAR WITH HAMAS.
BUT HIS GAMBLE DIDN’T PAY OFF. 
AFTER BEN GVIR’S PARTY CONDITIONED ITS SUPPORT FOR THE VOTE, THE GOVERNMENT PULLED THE LEGISLATION FROM THE DOCKET. AND A NUMBER OF SHAS MEMBERS OF KNESSET WALKED OUT OF THE SESSION IN PROTEST.
 SHAS THEN RELEASED A HEATED STATEMENT, TAKING PERSONAL AIM AT BEN GVIR. 
QUOTE GRAPHIC 
The right-wing bloc discovered again this evening that as regards the inflated balloon named Itamar Ben Gvir, as great as the expectation, so strong is the disappointment. Neither right nor strong. A series of embarrassing failures in his areas of responsibility, while spraying  responsibility, threats and accusations on the whole world. A Noise machine and zero action.   
Shas, which established the right-wing bloc, serves as the most stable element of the government, garners praise for its handling of the war issue, and conducts itself with full responsibility even at heavy costs, but Ben Gvir, in his childish behavior and insanity with his arrogance, is doing everything to destroy the government from within, destroy its foundations and loyally serves political parties the left   
Right-wing governments have always been overthrown by people of his type. The public will know and judge
IN RESPONSE, BEN GVIR SLAMMED SHAS LEADER ARYEH DERI AS QUOTE, ‘A LAPDOG OF THE LEFT’ AND ALSO TARGETED PRIME MINISTER BENJAMIN NETANYAHU.  
TWEET GRAPHIC
The absurd reality, in which we are floundering in Gaza, on the northern border, handcuffing the IDF, conducting negotiations with promiscuous opening conditions, on the way to a political settlement that is all surrender to terrorism on all fronts. When the Prime Minister conducts himself as a one-man government - makes the decisions alone, and isolates his natural partners in the government, including cabinet meetings that are emptied of meaningful content, it is intolerable. The Prime Minister must understand that "right-wing government" is not an empty expression, and the positions of the members of the government also have weight. We did not come to be cheered on the tribune. We came to influence. That's why our demand to enter the war management forum, which is now run as the concept cabinet - stands. 
AND NOW, ACCORDING TO ISRAELI KAN MEDIA NETWORK, DERI TOLD THE PRIME MINISTER THAT QUOTE, ‘THERE IS NO POINT FOR THE GOVERNMENT TO CONTINUE IF IT IS NOT FUNCTIONING.’  
THE INFIGHTING AGGRAVATES ALREADY STRAINED RELATIONS BETWEEN MEMBERS OF THE COALITION, WHICH OBSERVERS SAY, AND OPPONENTS HOPE, COULD BE  MOVING CLOSER TO COLLAPSE`
        },
        '2. ISRAEL:_ LARGEST PROTEST IN ITS HISTORY': {
            'text': `LEDE: Protests and rallies for the return of the hostages continue in Israel. Last night Tel Aviv saw some 500,000 protesting, making it the biggest demonstration in Israel’s history. More in this report from Emma Siegel:
CGS:
1.  	JON POLIN, Father of Murdered Hostage Hersh Goldberg-Polin 
2.  	EFRAT MACHIKAWA, Niece of Hostage Gadi Moses
3.  	ANDREY KOSLOV, Hostage Rescued from Hamas Captivity 
_______
6. (SOUNDBITE) (English) JON POLIN, Father of Murdered Hostage Hersh Goldberg-Polin 
For 330 days Mama and I sought the proverbial stone that we could turn to save you. Maybe, just maybe your death is the stone, the fuel that will bring home the remaining 101 hostages." 
ONE WEEK AFTER THE MURDER OF SIX ISRAELI HOSTAGES IN HAMAS CAPTIVITY, JUST DAYS BEFORE BEING FOUND BY THE IDF, ISRAELIS ARE STILL TEEMING WITH RAGE.  
NIGHT AFTER NIGHT, PROTESTERS HAVE TAKEN TO THE STREETS ACROSS THE COUNTRY, DEMANDING A DEAL THAT WILL SEE THE REMAINING 101 HOSTAGES IN GAZA BROUGHT HOME. 
5. SOUNDBITE (English) EFRAT MACHIKAWA, Niece of Hostage Gadi Moses
"They were alive. Six young, beautiful people. Innocent people. They were alive. They survived. They held strong. Just as Hersh's (killed hostage Hersh Goldberg-Polin) mom cried, 'stay strong'. He did. They all did. And yet, they were murdered in cold blood. 
ACCORDING TO PROTEST ORGANIZERS, SATURDAY NIGHT’S TEL AVIV PROTEST SAW SOME HALF A MILLION DEMONSTRATORS - MAKING IT THE LARGEST EVER PROTEST IN ISRAEL’S HISTORY. ORGANIZERS ALSO SAID THAT SOME 250,000 PROTESTED ACROSS THE REST OF THE COUNTRY. 
 
AT THE TEL AVIV PROTEST, RESCUED HOSTAGE ANDREY KOSLOV TOOK TO THE STAGE. HE SPOKE ABOUT HIS TIME IN CAPTIVITY WITH ALEX LOBANOV, ONE OF THE SIX HOSTAGES EXECUTED BY HIS CAPTORS. 
Andrey Koslov, Hostage Rescued from Hamas Captivity 
Alex was with us in hell for two months. The husband of Michal, the father of Tom and Kai. The son that he never got the chance to meet. He taught me words in Hebrew and told me his life story. We shared our fears and hopes. My wishes came true. For him, his fears came alive.
(WHITE FLASH)  
I got lucky. I don’t know why but I am blessed. I am here. Hersh, Eden, Carmel, Ori, Almog and Alex, they are not. 
AT LEAST TWO PROTESTERS WERE ARRESTED AT THE  MASSIVE TEL AVIV RALLY, AS CLASHES BROKE OUT BETWEEN POLICE AND DEMONSTRATORS WHEN ACTIVISTS ATTEMPTED TO BLOCK MAJOR ROADS AND HIGHWAYS.  
ON MONDAY MORNING, ISRAELI PRIME MINISTER BENJAMIN NETANYAHU REITERATED HIS WAR GOALS OF ELIMINATING HAMAS AND BRINGING BACK THE HOSTAGES. 
BIBI SOT
Last weekend,Germany's "Bild" newspaper published an official document of Hamas showcasing it's plan to sow division within us, engage in psychological warfare about the families of the abductees, exert internal and external political pressure on the government of Israel, tear us apart from the inside and continue the war, until further notice, until the defeat of Israel. The vast majority of Israeli citizens do not fall into this trap of Hamas. They know we are committed in all our efforts to achieve the goals of the war: eliminate Hamas, bring back all our hostages. 
THIS AS CIA DIRECTOR WILLIAM BURNS SAYS HE HELD TALKS OVER THE WEEKEND WITH ISRAEL, QATAR AND EGYPT IN AN EFFORT TO REACH A CEASEFIRE DEAL. BURNS SAID ON SATURDAY THAT HE BELIEVES REACHING A DEAL IS QUOTE, ‘A QUESTION OF POLITICAL WILL.’  
ELEVEN MONTHS INTO THE WAR, HOSTAGE FAMILIES, PROTESTERS AND MEDIATORS CONTINUE TO LEAVE NO STONE LEFT UNTURNED TO BRING BACK THE 101 HOSTAGES. `
        },
        '3. ISRAEL:_ ARCHAEOLOGY': {
            'text': `LEDE: Archaeologists in Jerusalem dug up the tusk of an elephant that dates back 500,000 years. The find was made during a two-week excavation by Tel Aviv University and Ben-Gurion University, called Operation Elephant. For more on the discovery, our Emma Siegel has the story:
CGS: 
1.      AVI LEVY, Manager of Excavation 
2.  	PROF. ISRAEL HERSHKOVITZ, Professor of Anatomy and Anthropology, Sackler Faculty of Medicine & Director of the Dan David Centre for Human Evolution and Biohistory Research 
[002 005 0057] THE ENTIRE TUSK OF A STRAIGHT TUSKED ELEPHANT. DUG UP BY ARCHAEOLOGISTS IN SOUTHERN ISRAEL AFTER HALF A MILLION YEARS
[0017 0026] AT TWO AND A HALF METRES LONG, AND WEIGHING ABOUT 150 KILOS, THE SHEER SIZE OF THE TUSK AND HOW WELL PRESERVED IT IS, WAS A MARVEL TO THE ARCHAEOLOGISTS AND CONSERVATORS ON SCENE. 
SOT: AVI LEVY 006 
(4:49-5:05) So from what we know, there isn’t  tusks that reveal that long and that preserved. It's quite unique. We need to think that it's dated around Half a million years ago, for a tusk to be preserved for half a million years ago is almost, almost a miracle. 
+
(8:46-8:58) We know in several of other sites around Israel, that we found the same species of elephant, the straight tusked elephant, but the preservation here is remarkable.
[0010 0015 0016 0007] NEXT TO THE DISCOVERY, STONE FLINTS WERE FOUND. SHOWING EVIDENCE OF HUMAN ACTIVITY. THE ARCHAEOLOGISTS COULDN’T SAY FOR SURE IF THESE FLINTS ARE PROOF THAT EARLY HOMININ HUNTED THE ELEPHANT, AS THE SIZE WOULD MAKE HUNTING EXTREMELY DIFFICULT. IN FACT, EARLY HUMANS MAY HAVE HAD A SPECIAL RELATIONSHIP WITH THE SPECIES. 
 SOT: PROF. ISRAEL HERSHKOVITZ  022 
(9:00-9:20) From all animals, elephants are more like human than any other animal I know. From a physiological point of view, from cognitive point of view, from the social point of view, I mean, we share so many things. Some people don't even know that if we are right or left handed, elephants are right tusk or left tusk handed. (9:20)
+
(9:25-9:33)They share too many similarities with humans so they probably will have significant symbol to the prehistoric people.
 [0023 0025 0030 0033] WHILE IT IS UNKNOWN HOW THE ELEPHANT DIED, THIS DISCOVERY GIVES A GLIMPSE INTO WHAT OUR WORLD WAS LIKE HUNDREDS OF THOUSANDS OF YEARS AGO. 
















































LEDE: ARCHAEOLOGISTS IN JERUSALEM DUG UP THE TUSK OF AN ELEPHANT THAT DATES BACK 500,000 YEARS.THE FIND WAS MADE EARLIER IN AUGUST DURING A TWO-WEEK EXCAVATION BY TEL AVIV UNIVERSITY AND THE BEN-GURION UNIVERSITY CALLED OPERATION ELEPHANT. FOR MORE ON THE DISCOVERY, OUR EMMA SIEGEL HAS THE STORY: 

CGS: 
1)	AVI LEVY, Manager of Excavation 
2)	PROF. ISRAEL HERSHKOVITZ, Professor of Anatomy and Anthropology, Sackler Faculty of Medicine & Director of the Dan David Centre for Human Evolution and Biohistory Research 
[002 005 0057] THE ENTIRE TUSK OF A STRAIGHT TUSKED ELEPHANT THAT HAS BEEN PRESERVED FOR HALF A MILLION YEARS WAS DUG UP BY ARCHAEOLOGISTS IN JERUSALEM. 

[0017 0026] AT TWO AND A HALF METRES LONG, AND WEIGHING ABOUT 150 KILOS, THE SHEER SIZE OF THE TUSK AND HOW WELL PRESERVED IT IS, WAS A MARVEL TO THE ARCHAEOLOGISTS AND CONSERVATORS ON SCENE. 
SOT: AVI LEVY 006 
(4:49-5:05) So from what we know, there isn’t  tusks that reveal that long and that preserved. It's quite unique. We need to think that it's dated around Half a million years ago, for a tusk to be preserved for half a million years ago is almost, almost a miracle. 
+	
(8:46-8:58) We know in several of other sites around Israel, that we found the same species of elephant, the straight tusked elephant, but the preservation here is remarkable.
[0010 0015 0016 0007] NEXT TO THE DISCOVERY, STONE FLINTS WERE FOUND. SHOWING EVIDENCE OF HUMAN ACTIVITY. THE ARCHAEOLOGISTS COULDN’T SAY FOR SURE IF THESE FLINTS ARE PROOF THAT EARLY HOMININ HUNTED ELEPHANTS, AS THE SIZE WOULD MAKE HUNTING THEM EXTREMELY DIFFICULT. IN FACT, EARLY HUMANS MAY HAVE HAD A SPECIAL RELATIONSHIP WITH THE SPECIES. 

SOT: PROF. ISRAEL HERSHKOVITZ  022 

(9:00-9:20) From all animals, elephants are more like human than any other animal I know. From a physiological point of view, from cognitive point of view, from the social point of view, I mean, we share so many things. Some people don't even know that if we are right or left handed, elephants are right tusk or left tusk handed. (9:20)
+	
(9:25-9:33)They share too many similarities with humans so they probably will have significant symbol to the prehistoric people.

[0023 0025 0030 0033] WHILE IT IS UNKNOWN HOW THE ELEPHANT DIED, THIS DISCOVERY GIVES A GLIMPSE INTO WHAT OUR WORLD WAS LIKE HUNDREDS OF THOUSANDS OF YEARS AGO. 






-It’s also found near human stone tools

-The flints existed next to the tusks

-It would have been hard to kill

-But we know there is a relationship between tusks and the hominids that lived here

-Early humans knew what to take from elephants 

-Behavior of early hominids is quite sophisticated

-Not known how the elephant died


Archaeologists in Jerusalem uncovered the entire tusk of an elephant that has been preserved for half a million years.


The Antiquities Authority explained that the presence of elephants in the area is a actually a well-known fact. 

However finding remains of elephants in archaeological sites where people once were is not common. 

The rare find though posed a challenge to the experienced archaeologists and conservators due to its size...which is about 2 and a half meters long, and weighs about 150 kilos. 


DISAPPEARANCE OF ELEPHANTS: INT. 22 @5:27
SIGNIFICANCE OF ELEPHANTS 8:33 


UNIQUENESS OF TUSK: INT 06 @ 4:57-5:10
SIGNIFICANCE OF ELEPHANTS @ 6:50
PRESERVATION/UNIQUENESS @ 8:50

-A tusk of a straight tusked elephant was discovered 

-The tusk is dated to around 500,000 years ago

- Tusk is around 2.5 meters long, around 150 kilos

-Elephants went extinct in the region around 400,000 years ago  

-In the past there were excavations here where they have found remains of elephants

-But what makes it unique is how well it’s preserved 

-For a tusk to be preserved from a half a million years ago it’s very unique

-It’s also found near human stone tools

-The flints existed next to the tusks

-It would have been hard to kill

-But we know there is a relationship between tusks and the hominids that lived here

-Early humans knew what to take from elephants 

-Behavior of early hominids is quite sophisticated

-Not known how the elephant died

-There were other tusks found in previous excavation

-In other sites they found species of straight tusk elephants

-But this is extremely well preserved`
        },
        '4. ISRAEL:_ HAREDI CONSCRIPTION RIOTS': {
            'text': `LEDE: While the Israel-Hamas war rages on and the northern border inches closer to an all-out war every day, Israel has begun to conscript ultra-orthodox men into IDF service. Today was the first recruitment day for those given draft orders but it quickly turned into riots and chaos. More in this report by Emma Siegel: 
CGS: 
1)	JACOB, Ultra-Orthodox Demonstrator
2)	YEHUDA HIRSCH, Ultra-Orthodox Demonstrator

IT WAS THE FIRST RECRUITMENT DAY FOR ISRAEL’S ULTRA-ORTHODOX WHO WERE GIVEN DRAFT ORDERS FROM THE IDF. BUT WHILE HUNDREDS SHOWED UP, IT WASN’T TO ENLIST. 
WHAT WAS SUPPOSED TO BE A DEMONSTRATION AGAINST ENLISTMENT TURNED INTO VIOLENT RIOTS OUTSIDE OF THE MILITARY CONSCRIPTION OFFICE.
ISRAEL’S ULTRA-ORTHODOX SECTOR OPPOSE MILITARY SERVICE AS THEY CONSIDER THE ARMY A DISTRACTION FROM TORAH STUDY AND A THREAT TO THEIR WAY OF LIFE. FOR DECADES RELIGIOUS LEADERS AND POLITICAL FIGURES FIERCELY RESISTED EFFORTS TO DRAFT YESHIVA STUDENTS, WHO HAVE RECEIVED EXEMPTIONS THEM FROM MILITARY SERVICE SO THEY CAN PURSUE RELIGIOUS STUDY. 
BUT, THE HAMAS LED MASSACRE ON OCTOBER 7TH AND SUBSEQUENT WAR DRAGGING INTO ITS TENTH MONTH HAS HEIGHTENED THE DEMANDS FOR THE ULTRA ORTHODOX TO SERVE IN THE ARMY. 
19. 02:25-02:40 SOUNDBITE 3 - Jacob, ultra-Orthodox demonstrator (male, Hebrew, 14 sec):
"I've never enlisted. I have an arrest warrant, but it doesn't bother me. Even if I will get arrested I’m willing to pay the price, as it is nothing compared to what we achieve, that no one will change their religion." 
ACCORDING TO THE ISRAELI POLICE, RIOTERS ATTEMPTED TO BREAK INTO THE CONSCRIPTION OFFICE, WHILE WREAKING HAVOC OUTSIDE -  THROWING OBJECTS AND SPITTING AT THE POLICE. AND ALSO BOOING AT ULTRA-ORTHODOX SOLDIERS IN THE VICINITY. 
20. 02:41-03:03 SOUNDBITE 4 - Yehuda Hirsch, ultra-Orthodox demonstrator (male, Hebrew, 22 sec):
"The Zionist army has decided to hold an enlistment day for ultra-Orthodox. They thought that, this way, they could enlist ultra-Orthodox. But an ultra-Orthodox becomes a Zionist when he joins the army. The only barrier between the ultra-Orthodox becoming Israeli is the army. When he doesn't join, he is not an Israeli, he is not loyal to the state and opposes it."
IN JULY ISRAEL ANNOUNCED IT WOULD SEND SOME 1-THOUSAND DRAFT ORDERS TO ULTRA-ORTHODOX MEN BETWEEN THE AGES OF 18-26, TARGETING ONLY THOSE THAT WERE NOT REGISTERED AS FULL-TIME YESHIVA STUDENTS.  
THOUGH NOT FULL TIME STUDENTS OF TORAH, THEIR REFUSAL TO SERVE WAS UNWAVERING.  
17. 01:59-02:10 SOUNDBITE 1 - Jacob, ultra-Orthodox demonstrator (male, Hebrew, 10 sec):
"We don't mind losing our lives. We don’t mind getting beaten up, or ending up in hospital or prison. The only thing we refuse is to give up the Jewish soul."
WHILE 450 YOUNG MEN WERE SUMMONED YESTERDAY, ONLY 8% OF THE RECRUITS SHOWED UP, WHILE 10 PROTESTERS WERE ARRESTED, AND THREE POLICE OFFICERS WOUNDED. 
`
        },
        '5. WEST BANK:_ IDF OPERATION': {
            'text': `LEDE: At least 17 Palestinian gunmen have been killed as Israeli Security Forces enter day two of one of the largest counter terror operations in decades in the West Bank. More in this report from Emma Siegel:
CGS: NONE
ISRAEL’S LARGE-SCALE OPERATION IN THE NORTHERN WEST BANK CONTINUES FOR A SECOND STRAIGHT DAY. 
OVERNIGHT, ISRAELI FORCES KILLED FIVE PALESTINIAN GUNMEN WHO WERE HIDING IN A MOSQUE IN THE  CITY OF TULKAREM. AMONG THOSE KILLED IS THE FORMER COMMANDER OF THE PALESTINIAN ISLAMIC JIHAD IN THE NUR SHAMS CAMP, MUHAMMAD JABER.  
ISRAEL’S INTERNAL SECURITY AGENCY, THE SHIN BET, SAYS JABER WAS INVOLVED IN PLANNING AND DIRECTING SEVERAL TERROR ATTACKS, INCLUDING THE MURDER OF AN ISRAELI IN THE WEST BANK TWO MONTHS AGO.  
THIS COMES A DAY AFTER IDF TROOPS LOCATED WHAT THEY SAY IS A QUOTE, ‘COMMAND ROOM’ USED BY TERROR OPERATIVES EMBEDDED WITHIN A MOSQUE. 
DRONE VIDEO FOOTAGE SHOWS THE ENTRANCE TO THE MOSQUE COVERED WITH ‘MARTYR POSTERS’ AND EQUIPMENT THAT SECURITY FORCES SAY ARE USED TO BUILD EXPLOSIVE DEVICES. 
SINCE THE LAUNCH OF THIS WIDESPREAD OPERATION EARLY WEDNESDAY, PALESTINIAN MEDIA HAS REPORTED THAT THE DEATH TOLL HAS CLIMBED TO 17. 
(tweet graphic goes here) THE SECURITY-GENERAL OF THE UNITED NATIONS, ANTONIO GUTERRES, CONDEMNED THE OPERATION AND CALLED FOR AN IMMEDIATE HALT TO THE IDF ACTIVITIES. 
ISRAEL’S AMBASSADOR TO THE UN, DANNY DANON - WHO HAS ONLY BEEN IN THE POSITION FOR NINE DAYS - WAS QUICK TO RESPOND
DANNY DANON TWEET PRETENDING TO BE A SOT
My response to the secretary general's call to stop IDF activity in Judea and Samaria and his condemnation of the "loss of children's lives during the operation": 
Since October 7, Iran has been working vigorously to introduce into Judea and Samaria (West Bank) sophisticated explosive devices that are intended to explode in the centers of cities in Israel.
The State of Israel cannot sit idly by and wait for the spectacle of buses and cafes exploding in city centers.
The activity of the IDF forces in Judea and Samaria is intended for the clear purpose of thwarting terrorist attacks and acts before they are carried out under Iranian direction.
ISRAEL HAS STEPPED UP ITS MILITARY OPERATIONS IN THE WEST BANK SINCE THE OUTBREAK OF THE WAR ON OCTOBER 7TH. MILITARY SOURCES SAY THAT THIS COUNTER TERRORISM RAID IS EXPECTED TO LAST FOR AT LEAST SEVERAL DAYS. 

LEDE: After yesterday’s convoy brought the hostages’ families from Tel Aviv to Israel’s border communities near the Gaza Strip, grandparents, parents and siblings used loudspeakers to call out to their loved ones in Hamas captivity. In their desperation to reach the hostages, several relatives breached the fence separating Israel from Gaza. More in this report by Emma Siegel (VTR IN) 
CGS  
VARDA BEN BARUCH, Grandmother of HostageEdan Alexander
RACHEL GOLDBERG-POLIN, Mother of Hostage Hersh Goldberg-Polin 

SOUND UP (SUBTITLED) 
Varda Ben Baruch, Grandmother of Edan Alexander
Edan Alexander, your grandparents are here, our dear soul! We're calling out to you—can you hear us? Your parents are waiting for you in America. 
FAMILIES OF HOSTAGES HELD BY HAMAS GATHERED AT ISRAEL’S SOUTHERN BORDER, CALLING OUT TO THEIR LOVED ONES IN CAPTIVITY SOMEWHERE BEYOND THE FENCE DIVIDING ISRAEL FROM GAZA.  
AFTER THE SUCCESSFUL RESCUE OF HOSTAGE FAHRAN AL-QADI, AND THE RECOVERY OF THE BODY OF A SLAIN IDF SOLDIER TAKEN INTO GAZA ON OCTOBER 7TH, 107 HOSTAGES REMAIN IN HAMAS CAPTIVITY. AL-QADI SAID THAT WHILE IN CAPTIVITY, HE WAS HELD WITH HOSTAGE ARYEH ZALMANOVICH FOR TWO MONTHS BEFORE ZALMANOVICH DIED NEXT TO AL-QADI. 
ZALMANOVICH, ONE OF THE FOUNDING MEMBERS OF KIBBUTZ NIR OZ, WAS DECLARED DEAD IN DECEMBER.  
WITH NO HOSTAGE DEAL IN SIGHT, THE FAMILIES OF HOSTAGES HAVE GROWN DESPERATE IN THEIR ATTEMPTS TO BRING THEIR LOVED ONES HOME, AND BREACHED THE FENCE SEPARATING KIBBUTZ NIRIM FROM THE GAZA STRIP, ABOUT TWO KILOMETRES AWAY FROM THE STRIP. 
THEIR DESPERATE ATTEMPT TO GET CLOSER TO THEIR FAMILY MEMBERS ENDED WITH INTERVENTION FROM ISRAELI SECURITY, WHO PLEADED WITH THEM TO WALK BACK TO THE KIBBUTZ. 
USING LOUDSPEAKERS, THEY CONTINUED TO CALL OUT ACROSS THE BARRIER. 
RACHEL GOLDBERG-POLIN, Mother of Hostage Hersh Goldberg-Polin  
It's Mama, Hersh. It’s day 328. We are all here, all the families of the remaining 107 hostages. Hersh, we are working day and night, and we will never stop.
I need you to know that I am giving you now the blessing I give you every single morning when I pray for you, and every Friday night I go out on the porch, towards Gaza, and I give you the Kohanic blessing: May God bless you and keep you. May God shine His face upon you and be gracious to you.
THE SISTER OF LIRI ELBAG, A FEMALE SURVEILLANCE SOLDIER TAKEN ON OCTOBER 7TH, SCREAMED FOR HER SISTER, IN THE HOPES THAT SHE CAN HEAR HER (SOUND UP)
ELEVEN MONTHS INTO CAPTIVITY, THE FAMILIES CONTINUE TO CALL FOR A CEASEFIRE, THE ONLY WAY, THEY BELIEVE, TO BRING ALL THE HOSTAGES BACK HOME.
`
        },
        '6. WEST BANK:_ AL JAZEERA OFFICE FORCED SHUT': {
            'text': `LEDE: In a live Al Jazeera broadcast around 3:00 am Sunday morning, Israeli troops entered its West Bank broadcasting office and handed the bureau chief an immediate closure notice. This as Israel continues to crack down on the Qatar-funded broadcaster, citing ‘national security threats.’ More in this next report from Emma Siegel:
CGS:  
1.       WALID AL-OMARI, Al Jazeera Bureau Chief for Israel & Palestinian Territories 
IN THE EARLY HOURS OF SUNDAY MORNING, THE IDF RAIDED NEWS NETWORK AL JAZEERA IN THE WEST BANK CITY OF RAMALLAH ON LIVE TV. THE BUREAU IS ORDERED CLOSED FOR 45 DAYS
AL JAZEERA DENOUNCED THE MOVE.  
9. (SOUNDBITE) (Arabic) AL JAZEERA BUREAU CHIEF FOR ISRAEL AND THE PALESTINIAN TERRITORIES, WALID OMARI, SAYING:
"This is a blatant attack on the Al Jazeera channel and Al Jazeera Network and those who are working with them, as well as on the freedom of speech and the task of delivering the truth. This is an attack that aims to obscure the truth." 
THE QATAR-FUNDED BROADCASTER HAS INCREASINGLY COME UNDER ISRAEL’S SCRUTINY FOR ITS COVERAGE AMID THE ISRAEL-HAMAS WAR AS WELL AS THE ALLEGED LINKS BETWEEN AL JAZEERA MEMBERS AND TERROR ORGANIZATIONS OPERATING IN THE GAZA STRIP. 
THE LATEST SHUTTERING OF AL JAZEERA’S OFFICE FOLLOWS AN ORDER ISSUED IN MAY THAT SAW ISRAELI POLICE RAID ITS EAST JERUSALEM AND NAZARETH BUREAUS - SEIZING EQUIPMENT, STOPPING ITS BROADCASTS AND BLOCKING ITS WEBSITES IN ISRAEL.  
8. SOUNDBITE (English) Al Jazeera’s local bureau chief, Walid al-Omari:
"The order is for 45 days based on the emergency law from the mandate days, the British mandate days in 1945. And they told us that it's closed now. We will stop working and nobody can go inside the office. This is the situation. It means that they extended the closure, the closure of our office, the shut down of our office in Jerusalem and banning our work inside of Israel to the West Bank. They extended to the West Bank now, which means that the Jazeera cannot work inside Israel and inside the West Bank. "
 (show graphic) ISRAELI MINISTER OF COMMUNICATIONS SHLOMO KARHI (CAR-EE) CONFIRMED THE RAID, CALLING AL JAZEERA QUOTE, THE MOUTHPIECE OF HAMAS AND HEZBOLLAH, SAYING ISRAEL WILL CONTINUE TO FIGHT THE ENEMY CHANNELS. 
 
IN APRIL, ISRAEL’S GOVERNMENT PASSED AN EMERGENCY LAW ALLOWING THE COUNTRY TO BAN AL JAZEERA FOR A 45-DAY PERIOD WITH FURTHER 45-DAY RENEWALS SUBJECT TO COURT APPROVAL. THE BAN HAS NOW BEEN RENEWED FOUR TIMES SINCE THEN. 
AS THE BROADCASTER CONTINUES TO OPERATE FROM NEIGHBOURING JORDAN, IT IS UNCLEAR HOW LONG IT WILL BE BANNED FROM BROADCASTING WITHIN ISRAEL OR THE WEST BANK. 


SHLOMO KARHI 
After much pressure and as we promised, this morning the security forces raided Al Jazeera's main offices in the West Bank  - the mouthpiece of Hamas and Hezbollah - complying with the order and closing  the station. We will continue to fight in the enemy channels and ensure the safety of our heroic fighters.`
        },
        '7. GAZA:_ FIGHTING LATEST': {
            'text': `LEDE: the IDF continues to dismantle Hamas terror networks throughout the Gaza Strip. This, as the terror leader Yahiya Sinwar’s whereabouts remain unknown with only a few reportedly knowing his location. more in this report by Emma Siegel:
CGS 
1)	HERZI HALEVI IDF Chief of Staff 
2)	BENJAMIN NETANYAHU Israeli Prime Minister 

50 TERROR SITES DEMOLISHED IN GAZA CITY’S  SA- JA- EE-A OVER THE PAST DAY. 
OVER 900 TERRORISTS KILLED THROUGHOUT THE RAFAH GROUND OPERATION. 
 THESE ARE THE NUMBERS THE IDF HAS RELEASED IN THE PAST DAY.  
1. (SOUNDBITE) (Hebrew) IDF  CHIEF OF STAFF HERZI HALEVI, SAYING:
"In the Rafah Brigade, what we’ve seen with our own eyes, and I don't want to say something out of thin air, we count over 900 terrorists killed, including commanders, at least one battalion commander, many company commanders and many operatives. The current effort, and the reason we are working here week after week, is now focused on the destruction of the terrorist infrastructure and the destruction of the underground infrastructure, which takes time."
BUT WHILE THE IDF IS MOVING DETERMINDEDLY THROUGHOUT THE GAZA STRIP TO ACHIEVE ITS GOALS, ONE QUESTION REMAINS: WHERE IS HAMAS LEADER YAHYA SINWAR? 
TERROR SOURCES TOLD A LONDON BASED, ARABIC LANGUAGE NEWSPAPER THAT ONLY ‘TWO OR THREE’ PEOPLE KNOW SINWAR’S WHEREABOUTS. THIS REPORT DID NOT SPECIFY WHETHER HE WAS HIDING ABOVE GROUND OR IN THE TUNNELS SNAKING UNDERNEATH GAZA.  
SOURCES ALSO TOLD THE SAUDI-OWNED OUTLET THAT THEY DON’T THINK SINWAR WILL EXCEPT ACCEPT A DEAL THAT SEES HIM EXILED. GOING AS FAR TO SAY, SUCH A PROPOSAL WOULD BE QUOTE ‘FUNDAMENTALLY UNACCEPTABLE’ FOR SINWAR. 
 MEANWHILE, ISRAELI PRIME MINISTER LAST NIGHT SLAMMED A NEW YORK TIMES REPORT WHICH ALLEGED ISRAEL WOULD BE WILLING TO END THE WAR BEFORE ACHIEVING ALL OF ITS GOALS.  
1. (SOUNDBITE) (Hebrew) ISRAELI PRIME MINISTER BENJAMIN NETANYAHU SAYING:
I don't know who those unnamed officials are, but I am here to convey unequivocally that this is not going to happen. We will end the war only after we achieve all of its goals, including the elimination of Hamas and the release of all of our hostages. The political echelon defined these goals to the IDF and the IDF has all the means to achieve them. We do not give in to the winds of defeat, not in the New York Times and not anywhere else. We are imbues with the spirit of victory. 
BUT AS ISRAEL HAS PLACED THE ELIMINATION OF SINWAR AS ONE OF ITS TOP GOALS, AND THE TERROR LEADER CONTINUES TO EVADE THE IDF, IT REMAINS TO BE SEEN EXACTLY HOW AND WHEN THIS WAR WILL END. 
`
        },
        '8. GAZA:_ POLIO VACCINATION CAMPAIGN': {
            'text': `LEDE: Humanitarian pauses in the Gaza Strip enter the second day of a nine-day polio vaccination drive, following the diagnosis last month of the first case of the disease in the enclave in 25 years. More in this report by Emma Siegel:

CGS: 
1)	NEVIN ABU AL-JIDYAN, Gazan resident
2)	WAFAA ABDELHADI Displaced Palestinian Mother
__

DAY TWO OF THE MASS POLIO VACCINATION CAMPAIGN IS UNDERWAY IN THE GAZA STRIP. 
 
THIS COMES AFTER A POLIOVIRUS CASE WAS RECORDED IN THE ENCLAVE FOR THE FIRST TIME IN 25 YEARS. 
 
5. SOUNDBITE (Arabic) Nevin Abu Al-Jidyan, Gazan resident:
"Then I got a call from the local health authorities. They said my son is the first confirmed case of polio in Gaza (in 25 years)."
 
HER SON TURNED 1 YEAR OLD ON SEPTEMBER 1ST. 
 
8. SOUNDBITE (Arabic) Nevin Abu Al-Jidyan, Gazan resident:
"He is one year old on Sept 1. Most children of his age have started to learn how to walk, but he cannot even move."
 
HEALTH WORKERS HAVE SINCE LAUNCHED A VACCINATION DRIVE TO WARD OFF A FEARED POLIO OUTBREAK. BOTH ISRAEL AND HAMAS HAVE AGREED TO A PARTIAL PAUSE IN FIGHTING FOR DAYS AT A TIME IN SPECIFIC AREAS OF THE STRIP FOR LOCAL HEALTH OFFICIALS AND THE UNITED NATIONS TO ADMINISTER THE VACCINATIONS. 
 
DESCRIBED AS “HUMANITARIAN PAUSES,” THE WORLD HEALTH ORGANIZTION AND UNICEF, IN COORDINATION WITH THE IDF’S SOUTHERN COMMAND AND THE AGENCY COGAT, ARE AIMING TO USE THIS TIME TO PROVIDE ORAL VACCINES TO MORE THAN 640,000 CHILDREN UNDER THE AGE OF 10. 
 
5. (SOUNDBITE) (Arabic) DISPLACED PALESTINIAN MOTHER, WAFAA ABDELHADI, 28, SAYING:
"Thank God, I am happy that I vaccinated my children against polio so that they don't suffer from anything in the future God willing. Hopefully all Gazan children get vaccinated not only against polio, but with other vaccinations too."
 
BUT, IT IS NOT JUST GAZANS WHO ARE IN NEED OF VACCINES. LAST WEEK, THE HEAD OF ISRAEL’S ASSOCIATION OF PUBLIC HEALTH PHYSICIANS CALLED ON THE UN TO MAKE SURE ISRAELI HOSTAGES IN GAZA ARE INCLUDED IN THE CAMPAIGN. 
 
THE TWO YOUNGEST HOSTAGES, ARIEL BIBAS, 5 YEARS OLD, AND KFIR BIBAS, JUST OVER ONE, ARE ALSO IN NEED OF THE POLIO VACCINATION. 
 
POLIOVIRUS IS HIGHLY INFECTIOUS AND CAN CAUSE PARALYSIS AND DEATH IN ITS MOST SEVERE CASES. 
 
IT’S MOST OFTEN SPREAD THROUGH SEWAGE AND CONTAMINATED WATER - SOMETHING THAT HAS BECOME AN INCREASING PROBLEM IN THE GAZA STRIP AS THE ISRAEL-HAMAS WAR RAGES ON FOR THE ELEVENTH MONTH. 
 `
        },
        '9. IRAN:_ MORALITY POLICE': {
            'text': `ACCORDING TO REPORTS, IRAN IS DISBANDING ITS CONTROVERSIAL MORALITY POLICE. THIS COMES AFTER TWO MONTHS OF 
INTENSE PROTESTS IN THE COUNTRY,  SPARKED BY  THE DEATH IN DETENTION OF 22 YEAR OLD  MAHSA  AMINI AFTER HER ARREST BY THE MORALITY POLICE FOR ALLEGEDLY HAVING INCORRECTLY WORN HER  HIJAB.
THE MORALITY POLICE ARE PART OF THE COUNTRY’S ATTEMPT TO KEEP THE PUBLIC IN LINE WITH THE ISLAMIC VALUES OF THE THEOCRATIC REGIME IN IRAN. THESE POLICE HAVE THE POWER TO STOP WOMEN FOR IMPROPERLY WEARING THE  HIJAB, AND TAKE THEM INTO CUSTODY FOR QUESTIONING.
THOUSANDS HAVE BEEN ARRESTED DURING THESE INTENSE TWO MONTH OF DEMONSTRATIONS, AND SOME HAVE FACED THE DEATH PENALTY AS THE REGIME ATTEMPTS TO SQUASH THE PROTESTS THAT CONTINUE TO SWEEP THE COUNTRY IN WAKE OF  AMINI’S DEATH.
IS THE ABOLITION OF THE MORALITY POLICE THE FIRST STEP TO RECOGNIZING THE SUCCESS OF THE WOMEN’S MOVEMENT IN IRAN? 
WILL THERE BE AN END TO THE HEADSCARF MANDATE? 
AND WHAT DOES THIS MEAN FOR THE FUTURE OF IRAN?
`
        },
        '10. LEBANON:_ MYSTERIOUS ASSASSINATION': {
            'text': `LEDE: No one is taking responsibility for last week’s mysterious shooting death of a Lebanese money changer who was seen as a key conduit for funds between Iran and Hezbollah. But now Lebanese officials are pointing the finger at Israel’s Mossad intelligence agency. More in this report from Emma Siegel: 
CGS: 
1)	BASSAM MAWLAWI, Lebanese Interior Minister
2)	ROY ABOU CHEDID, Beit Meri Mayor

PISTOLS EQUIPPED WITH SILENCERS. GLOVES SITTING IN A BUCKET OF WATER AND CHEMICALS, ALLEGEDLY INTENDED TO REMOVE FINGERPRINTS AND OTHER EVIDENCE. 
THESE WERE THE OBJECTS FOUND IN A VILLA ON THE EDGE OF THE QUIET TOWN, BEIT MERI, IN LEBANON EARLIER THIS MONTH. WHERE THE BODY OF HEZBOLLAH-LINKED LEBANESE CURRENCY EXCHANGER MOHAMMAD SROUR WAS DISCOVERED RIDDLED WITH BULLET HOLES AND SURROUNDED BY THOUSANDS OF DOLLARS IN CASH. 
 AND NOW, LEBANON’S INTERIOR MINISTER IS ALLEGING THAT THE MYSTERIOUS ABDUCTION AND KILLING OF SROUR WAS LIKELY THE WORK OF ISRAELI SPY AGENCY, THE MOSSAD. 
6. SOUNDBITE (Arabic) Bassam Mawlawi, Lebanese Interior Minister:
“The Lebanese security agencies have suspicion or accusations that Mossad is behind this operation. Why do they have this accusation when we are always told that crimes in Lebanon are mostly or all ordinary criminal crimes and do not have a security background? Why have we shifted this accusation to a security issue related to Mossad? According to the Lebanese security agencies, this person, his actions, and the way the crime was carried out led to this accusation. Mohammad Srour's activities in money exchange are known. And it is known that he transfers money from a specific party to another party."
SROUR WAS SANCTIONED BY THE U.S. IN 2019 FOR GIVING QUOTE “FINANCIAL, MATERIAL, TECHNOLOGICAL SUPPORT, OR OTHER SERVICES” TO HAMAS AND FOR HIS AFFILIATION WITH HEZBOLLAH. HE WAS ACCUSED OF TRANSFERRING TENS OF MILLIONS OF DOLLARS ANNUALLY FROM IRAN’S ISLAMIC REVOLUTIONARY GUARD CORPS TO HAMAS’ MILITARY WING. 
 THREE LEBANESE JUDICIAL OFFICIALS FAMILIAR WITH THE INVESTIGATION TOLD THE AP THAT A MAN POSING AS A CUSTOMER HAD CONTACTED SROUR FROM ABROAD AND ASKED HIM TO DELIVER A CASH TRANSFER TO A WOMAN IN THE MOUNTAIN RESORT OF BEIT MERI.
HE WAS CONTACTED BY THE SAME PERSON WITH ANOTHER REQUEST A DAY AFTER HIS FIRST VISIT. AFTER THIS, HIS FAMILY LOST CONTACT WITH HIM.
14. SOUNDBITE (Arabic) Roy Abou Chedid, Beit Meri Mayor:
“The perpetrator of the operation is not an ordinary person seeking revenge or someone aiming for money as money was left on the ground, as far as we know. The one who carried out the operation is someone who planned, organised, prepared themselves, and created the most suitable environment. Because today, the most suitable place to attract someone like the deceased is an area like Beit Mari and Monte Verde which are secure areas. This means that if he (the victim) wanted to exchange an amount of money, he knew that he would be in a securely protected zone."
THE ISRAELI PRIME MINISTER’S OFFICE, WHICH OVERSEES MOSSAD, DID NOT IMMEDIATELY RESPOND TO A REQUEST FOR COMMENT.

`
        },
        '11. LEBANON:_ MUSEUM REOPENS AFTER BEIRUT BLAST': {
            'text': `LEDE: The reopening of the Sursock museum in Lebanon almost three years after the Beirut port blast destroyed parts of it brings a sense of hope to a country still reeling from the explosion's aftermath. i24NEWS’ Emma Siegel has more in this next report: 
 CGs:
1.  DIRECTOR OF SURSOCK MUSEUM, KARINA EL HELOU
2.  CHAIR OF SURSOCK MUSEUM BOARD, TAREK MITRI 

ALMOST THREE YEARS AFTER THE BEIRUT PORT BLAST SHOOK THE COUNTRY AND KILLED 215 PEOPLE, LEBANON’S SURSOCK MUSEUM HAS REOPENED ITS DOORS.  
LOCATED IN ONE OF THE AREAS MOST AFFECTED BY THE AUGUST 2020  EXPLOSION, THE MUSEUM WAS DEVASTATED AND HAS UNDERGONE RESTORATION WITH THE HELP OF ITALIAN, FRENCH AND LEBANESE FUNDING. 
30. (SOUNDBITE) (English) DIRECTOR OF SURSOCK MUSEUM, KARINA EL HELOU, SAYING:
"Our reopening is a resistance, this one. It is a joyful moment, we are very proud. I am very proud of the teams but I am very as well... I am still, like many, angry at our state and I think that we shouldn’t just celebrate, we should show that this is an act of resistance."
 
PART OF THE RESTORATION PROCESS INCLUDED REPLACING THE SIGNATURE MULTI COLOURED GLASS WINDOWS THAT THE MUSEUM IS KNOWN FOR, WHICH WERE COMPLETELY SHATTERED.
9. (SOUNDBITE) (Arabic) CHAIR OF SURSOCK MUSEUM BOARD, TAREK MITRI, SAYING:
"I previously had doubts if we will get to restore the museum and bring it back to how it was before the blast, 'is this possible?', I always thought about this question. Now I have the answer: 'Yes, it is possible'. 
IN THE MIDST OF AN  UNPRECEDENTED FINANCIAL CRISIS AND INSTABILITY, THIS IS A SMALL SLIVER OF HOPE THE PEOPLE OF LEBANON HAVE BEEN LOOKING FOR.

`
        },
        '12. TURKEY:_ STRAY DOGS MASSACRE LAW': {
            'text': `LEDE: Turkey has been in uproar since the parliament passed what critics call the “massacre law” against stray dogs in the country. Now, activists and organizations are working to get dogs out of Turkey and into loving homes abroad to spare them from a life living in a shelter, or euthanization. More in this report from Emma Siegel:

CGS: 
1)	NILGUL SAYAR Shelter Owner  
2)	CAROLINE DIELEMAN Deezi’s New Owner 
__________________________
GIVEN A NEW CHANCE AT LIFE. 

AFTER TURKEY’S PARLIAMENT PASSED A LAW IN JUNE ORDERING THE IMPOUNDING OF THE COUNTY’S 4 MILLION STRAY DOGS, ANIMAL RIGHTS ACTIVISTS ARE DOING EVERYTHING THEY CAN TO GET THE CANINES OUT OF THE COUNTRY. 

TURKEY HAS SEEN FIERCE PROTESTS SINCE JUNE WHEN THE LAW WAS PASSED, CALLING THE BILL QUOTE, ‘THE MASSACRE LAW.’ 

TURKISH SHELTERS ARE UNABLE TO ACCOMODATE THE MILLIONS OF STRAYS AND IT CAN OFTEN TAKE YEARS TO FIND HOMES FOR OLDER, LARGER OR DISABLED DOGS. 

ANIMAL LOVERS FEAR THAT WITH NO SPACE FOR ALL OF THE DOGS, MANY WILL BE EUTHANIZED.

ONE ISTANBUL SHELTER HAS SEEN A FLOOD OF PEOPLE FROM AROUND THE WORLD LOOKING TO ADOPT. 

5. (SOUNDBITE) (Turkish) SHELTER OWNER, NILGUL SAYAR, SAYING:
"After the law, there was really a huge demand. Right now, we have 20-25 dogs who were ready, who had their titration ready and were advertised on the website. 
TWO OF THOSE DOGS ARE DALI AND DEEZI, FURRY FRIENDS NOW LIVING A COMFORTABLE LIFE IN THE NETHERLANDS AFTER WAITING IN A TURKISH SHELTER FOR FOUR YEARS. 

DALI, NAMED AFTER PAINTER SALVADOR DALI'S THREE-LEGGED CHAIR, LOST A LEG WHEN SHE HIT BY A CAR AS A TWO-MONTH-OLD PUP WANDERING THE STREETS. 
DALI IS NOW IN A FOSTER HOME IN THE NETHERLANDS AWAITING ADOPTION WHILE DEEZI IS SETTLING INTO HER NEW LIFE WITH A LOVING OWNER.

28. (SOUNDBITE) (English) DEEZI'S NEW OWNER, CAROLINE DIELEMAN, 51, SAYING:
"This new law (in Turkey) makes me feel sick, really physically but also powerless because there is nothing you can do. Yeah, well - a little. You can adopt a dog, of course, and then another dog from the streets can go into the shelter, but the way they do it gives me nightmares. It makes me angry, it makes me cry. It's awful
WHILE SENDING DOGS FROM  TURKEY TO THE EU WITH ALL THE NECESSARY PAPERWORK AND VACCINATIONS COSTS JUST OVER ONE-THOUSAND U.S. DOLLARS, TO ANIMAL LOVERS AND ACTIVISTS, THE COST IS WORTH IT. 
`
        },
        '13. TURKEY:_ SYRIAN-TURKISH CLASHES': {
            'text': `LEDE: Turkey has closed its main border crossing into Syria after Turkish troops came under fire from Syrians. It’s part of a growing refugee crisis. More in this report from Emma Siegel:
CGS: 
1)	AHMED SHUKEIR, Protester
2)	RECEP TAYYIP ERDOGAN, Turkish President 
A VIOLENT NIGHT IN TURKEY AND SYRIA. 
ACCUSATIONS THAT A SYRIAN MAN LIVING IN CENTRAL TURKEY ABUSED A CHILD ERUPTED INTO OVERNIGHT RIOTS THAT TARGETED SYRIAN BUSINESSES IN THE ANATOLIAN CITY.  
THE TURKISH INTERIOR MINISTER SAID OVER 450 RIOTERS WERE ARRESTED. LEAVING IN THEIR WAKE OVERTURNED CARS AND BUSINESSES SET ABLAZE. 
THESE CLASHES SPILLED OVER INTO TURKISH CONTROLLED AREAS OF NORTHERN SYRIA, WHERE HUNDREDS OF OUTRAGED SYRIANS PROTESTED AGAINST TURKEY AND THE ACTIONS OF ITS PEOPLE. 
THE SYRIAN BORDER CITY OF AFRIN WAS THE EPICENTER OF THE MOST VIOLENT CLASHES, WITH AT LEAST 4 KILLED IN EXCHANGES OF FIRE BETWEEN ARMED PROTESTERS AND TURKISH TROOPS.
SOUNDBITE (Arabic) Ahmed Shukeir, protester:
"There were different actions by the Turkish government that triggered people here. One of these acts is empowering racism against Syrians in Turkey. Whether it was what happened in Kaysiri, or Gazintap before that. These incidents are supported by Turkish regime to pressure our areas to reach a point of normalization with Assad regime. But hopefully, this will never happen. 
IN WAKE OF THE VIOLENT NIGHT, TURKEY HAS CLOSED ITS MAIN BORDER CROSSING INTO NORTHWEST SYRIA UNTIL FURTHER NOTICE. THIS BORDER IS A MAIN TRADE AND PASSENGER CONDUIT FOR MORE THAN THREE MILLION INHABITANTS.  
MEANWHILE, TURKISH PRESIDENT ERDOGAN HAS BLAMED OPPOSITION PARTIES IN TURKEY FOR THE RAMPAGE, POINTING A FINGER AT THEM FOR “HATRED POLITICS.”  
THERE ARE APPROXIMATELY 3.6 MILLION SYRIAN REFUGEES LIVING IN TURKEY. 
BUT AN-KUH-RA HAS OFTEN STRUGGLED TO PROPERLY INTEGRATE THEM INTO TURKISH SOCIETY. THIS, WITH A STRUGGLING ECONOMY, HAS TURNED THE REFUGEE CRISIS INTO A CENTRAL DEBATE IN TURKISH POLITICS.  
8. (SOUNDBITE) (Turkish) TURKISH PRESIDENT, TAYYIP ERDOGAN, SAYING:
“No one can reach anywhere by fueling xenophobia and hate towards refugees. One of the reasons for the saddening events caused by a small group in Kayseri is the opposition’s poisonous discourse. No matter who it is, burning people’s homes and their relatives, engaging in vandalism, setting fire to the streets is unacceptable.” 
THESE OVERNIGHT SKIRMISHES COME JUST DAYS AFTER ERDOGAN SAID HE WAS OPEN TO MEETING WITH SYRIAN PRESIDENT ASSAD, IN A FIRST STEP TO RESTORING TIES BETWEEN THE TWO COUNTRIES. 
BUT WHILE ITS LEADERS MIGHT BE OPEN TO NORMALISING TIES AGAIN, THE QUESTION IS, WILL IT’S PEOPLE FOLLOW SUIT? (END WITH RIOT SHOTS)


`
        },
        '14. SYRIA:_ OLIVE OIL INDUSTRY': {
            'text': `LEDE: After years of economic and political instability, as well as the detrimental impacts of climate change, Syria's olive oil industry is making a comeback. i24NEWS’ Emma Siegel has more in this next report: 

CGS: 

1)	Head of Olive Department in Syrian Agriculture Ministry ABIR JAWHAR 


AFTER 11 YEARS OF STEEP DECLINE, SYRIA’S OLIVE INDUSTRY HAS SEEN A BETTER-THAN EXPECTED HARVEST IN 2022, A SIGN OF HOPE FOR THE RENOWNED GLOBAL EXPORT. 

THOUGH THE OLIVE OIL PRODUCTION IS EXPECTED TO BE AROUND 125,000 TONNES – A MASSIVE SURGE FROM LAST YEARS 80,000 – IT IS STILL FAR AWAY FROM THE PRE-WAR LEVELS. 

SOT
“It was ranked fourth in the world in terms of production in the 2011-2012 season, with olive oil production reaching 198,000 tons. Unfortunately, we have now declined due to low productivity and climate change and not being able to give the olive tree the necessary care for it to have a high or abundant production."


WHILE A COMBINATION OF THE CIVIL WAR IN SYRIA, SANCTIONS THAT GUTTED ITS ECONOMY AND CLIMATE CHANGE HAVE AFFECTED THE EXPORT, THE BIGGEST CHALLENGE FACING FARMERS IS THE HIGH PRICE OF FERTILISERS AND A LACK OF FUEL. 

THE INSTABILITY HAS LED TO SYRIAN OLIVE OIL BEING OFFERED AT PRICES TOO HIGH TO GAIN COMPETITIVENESS ON A GLOBAL SCALE.


SOT 
“If the requirements are available and the sanctions are removed, it will be easy to secure the necessities at reasonable prices so that we can provide the tree with the needed care. Surely this will help the production become better."
WITH THE HOPES OF EXPORTING TO COUNTRIES IN EUROPE, THE U.S., ASIA AND GULF COUNTRIES, THIS MIGHT BE JUST THE BEGINNING OF SYRIA’S OLIVE OIL RESURGENCE. 

`
        },
        '15. LIBYA:_ MASS GRAVE UNCOVERED': {
            'text': `LEDE: Meanwhile in Libya, reports of a mass grave have been found in the desert along the Libyan-Tunisian border. In March, 65 bodies were found in a grave in southwest Libya. More in this report by Emma Siegel: 
CGS:
1)	VOLKER TURK UN High Commissioner for Human Rights 
FOUR MONTHS AFTER A MASS GRAVE HOLDING AT LEAST 65 BODIES WAS DISCOVERED IN SOUTHWEST LIBYA, THE UN COMMISSIONER FOR HUMAN RIGHTS IS SPEAKING ABOUT MORE DISTURBING DEVELOPMENTS IN THE REGION. 
6. (SOUNDBITE) (English) U.N. HIGH COMMISSIONER FOR HUMAN RIGHTS, VOLKER TURK, SAYING:
“And in March this year a mass grave was discovered in south-western Libya, containing at least 65 bodies presumed to be migrants. As if this were not horrific enough, we are following up on reports of another mass grave recently discovered in the desert area at the Libyan-Tunisian border. I urge the authorities to respond swiftly to our inquiries, and to investigate these crimes fully. The loved ones of those who died have every right to know the truth. And the responsibility for investigating these crimes falls squarely with the Libyan authorities. 
AS WITH THE GRAVE FOUND IN MARCH, THERE ARE NO DETAILS OF THE SUSPECTED IDENTITIES OF THE VICTIMS, OR HOW THE SITE WAS INITIALLY UNCOVERED. 
BUT WHILE MUCH OF THE CIRCUMSTANCES REMAIN UNKNOWN, SOME SPECULATE THAT THEY DIED IN THE PROCESS OF BEING SMUGGLED THROUGH THE DESERT, A COMMON PRACTICE ALONG THE LIBYA-TUNISIA BORDER. 
10. (SOUNDBITE) (English) U.N. HIGH COMMISSIONER FOR HUMAN RIGHTS, VOLKER TURK, SAYING:
“We are seeing widespread human rights violations against migrants, refugees and asylum-seekers. The dehumanization of these individuals who are in a vulnerable situation continues to take place at the hands of both State and non-State actors, often working in collusion. Trafficking, torture, forced labour, extortion, starvation in intolerable conditions of detention, mass expulsions, the sale of human beings, including children, perpetrated at scale, with impunity.”
 
THIS COMES AFTER LIBYA REOPENED ITS BORDER CROSSING WITH TUNISIA AFTER A 3-MONTH CLOSURE, FOLLOWING CLASHES IN MARCH BETWEEN LIBYAN GOVERNMENT FORCES AND GUNMEN. 
MEANWHILE, THE DANGEROUS REGION IS WITNESSING ANOTHER MASS CASUALTY - FOURTEEN PEOPLE, INCLUDING 12 SYRIANS, DIED AFTER BECOMING STRANDED ALONG LIBYA’S OTHER BORDER, IN THE ALGERIAN DESERT. THE YOUNGEST VICTIM WAS JUST TEN YEARS OLD. 
LIKE WITH LIBYA, ALGERIA HAS A HUGE NETWORK OF SMUGGLING OPERATIONS FOR MIGRANTS ATTEMPTING TO CROSS INTO EUROPE, WILLING TO RISK THE TREACHEROUS JOURNEY IN HOPE OF A BETTER LIFE. 


`
        },
        '16. MOROCCO:_ EARTHQUAKE RECOVERY ONE YEAR LATER': {
            'text': `LEDE: Morocco is marking one year since a catastrophic earthquake killed almost 3-thousand people and displaced tens of thousands more. While rebuilding efforts are underway, many residents feel that construction is not moving fast enough. More in this report by Emma Siegel: 
CGS:  
1.      ABDERRAHIM ALACHOUN, Plumber in Amizmiz
2.      YASSINE AÏT SI MHANNED, Resident of the Village Tiniskt 
_____________________________ 
SEPTEMBER 8TH 2023 JUST BEFORE MIDNIGHT, A DEVASTATING 6.8 EARTHQUAKE HIT MOROCCO’S HIGH ATLAS - A MOUNTAIN RANGE IN THE COUNTY’S CENTRE.  
AND NOW, ONE YEAR LATER, THE MOUNTAIN COMMUNITIES ARE STILL TRYING, AND STRUGGLING, TO REBUILD.  
OVER 55,000 HOMES WERE DESTROYED IN THE STRONGEST QUAKE MOROCCO HAS SEEN SINCE 1960,  LEAVING SOME 380,000 PEOPLE WITHOUT SHELTER. 
IN SOME PLACES, RESIDENTS HAVE BEGUN TO RECONSTRUCT THEIR HOMES - WHILE STILL ACTUALLY AWAITING GOVERNMENT APPROVAL TO REBUILD. 
4. SOUNDBITE (Arabic) Abderrahim Alachoun, Plumber in Amizmiz
"Thank God things have improved a bit, the danger has been removed. We also need to work differently than before, when no one was working and we were suffering. Today, things are gradually getting better. Some residents have benefitted from the [government] support, and others are waiting." 
BUT MANY HAVE NOT BEEN SO FORTUNATE. THE MAJORITY OF THE DISPLACED ARE STILL LIVING IN TENTS, VULNERABLE TO THE INTENSE SUMMER HEAT AND FREEZING WINTER CONDITIONS.  
52. 05:19-05:49 SOUNDBITE 2 - Yassine Aït Si Mhanned, Resident of Tiniskt Village "There are still people living in tents and suffering from the heat, and they are certainly at risk of facing the cold here in the tents. There is also the problem of support for people who have completely lost their homes and who have received compensation of 80,000 dirhams, but should this be used to build? To buy land? 
SO FAR, ONLY AROUND 1,000 HOMES HAVE BEEN REBUILT. 
OFFICIALS SAY THAT RESTORATION WILL COST APPROXIMATELY 12 BILLION U.S. DOLLARS OVER THE SPAN OF FIVE YEARS. WHILE THE GOVERNMENT HAS REBUILT SOME INFRASTRUCTURE DESTROYED LAST YEAR, IT HAS ALSO ACKNOWLEDGED THAT THERE IS A NEED TO SPEED UP HOME REBUILDING. AS RESIDENTS CONTINUE TO WAIT, AND HOPE, FOR THEIR LIVES TO GO BACK TO NORMAL. 


`
        },
        '17. EGYPT:_ COP27 KICKS OFF': {
            'text': `LEDE: The COP27 climate summit begins today in Sharm El-Sheikh, Egypt, where Israel is expected to showcase the largest pavilion. i24NEWS’ Emma Siegel has more on the story: 

CGS:

1)	PHOEBE PLUMMER Just Stop Oil Supporter & Student 
2)	ISAAC HERZOG Israeli President


THE LAND OF ALTERNATIVE MILK, ALTERNATIVE HONEY, AND ALTERNATIVE MEAT. THIS IS HOW ISRAEL HAS PROMOTED ITSELF FOR THE UPCOMING COP27 UNITED NATIONS CLIMATE SUMMIT IN EGYPT’S SHARM EL- SHEIKH. (SHARM AL-SHECH) 

THE EVENT KICKS OFF ON NOVEMBER 7, WHERE ISRAEL WILL SHOWCASE ONE OF THE LARGEST PAVILIONS, PROMISING SOLUTIONS TO GLOBAL ENVIRONMENTAL ISSUES. 

HERES WHAT THIS YEARS COP27 PRESIDENT SAID ABOUT THE SUMMIT 

2. SOUNDBITE 2 - Sameh Shoukry, Egyptian Foreign Minister, President of COP27 (male, Arabic, 26 sec):
"I assure you here that Egypt will spare no effort in this regard and that we will work to provide the best conditions that allow all participants from all parties, governmental and non-governmental, to contribute to the success of this important event and to ensure that it produces the results that we all aspire to."
THIS SENTIMENT IS ECHOED IN THE COP26’S PRESIDENT’S SPEECH AS WELL
3. SOUNDBITE 3 - Alok Sharma, President of COP26 (male, English, 17 sec):
"Whilst I do understand that leaders around the world have faced competing priorities this year, we must be clear, as challenging as our current moment is, inaction is myopic and can only differ climate catastrophe."
4. SOUNDBITE 4 - Alok Sharma, President of COP26 (male, English, 7 sec):
"How many more wake-up calls does the world... do world leaders actually need."

THIS IS THE MOST SIGNIFICANT ISRAELI PARTICIPATION SINCE THE INTERNATIONAL CONFERENCE ON CLIMATE CHANGE BEGAN IN 1995.

LAST WEEK ISRAELI PRESIDENT ISAAC HERZOG TWEETED THE IMPORTANCE OF THIS UPCOMING EVENT 

SOT: HERZOG TWEET READ OUT
THE CLIMATE CRISIS IS AN INTERNATIONAL AND ISRAELI EMERGENCY. AN IMPORTANT OPPORTUNITY TO DISPLAY ISRAELI ACCOMPLISHMENTS AND KNOW-HOW IN THE FIELD OF THE CLIMATE CRISIS.

HERZOG WILL BE REPRESENTING THE COUNTRY AT THE SUMMIT, ALONGSIDE A NUMBER OF SENIOR ISRAELI OFFICIALS FROM BOTH THE PUBLIC AND PRIVATE SECTORS.

THIS CLIMATE SUMMIT COMES IN THE WAKE OF EXTREME EFFECTS OF CLIMATE CHANGE. SEVERE FLOODS IN PAKISTAN, DROUGHTS ACROSS EUROPE AND THE RISE IN DESPERATE PLEAS FOR CHANGE FROM YOUNG CLIMATE ACTIVISTS. 

SOT: JUST STOP OIL SUPPORTER AND STUDENT, PHOEBE PLUMMER

What is worth more, art or life? (...) are you more concerned about the protection of a painting, or the protection of our planet, and people?

WITH THE WORLD CONTINUING TO WARM AT ALARMING RATES, WILL THIS SUMMIT HELP IN THE FIGHT AGAINST CLIMATE CHANGE, OR WILL WE CONTINUE TO SEE ACTIVISTS THROW SOUP ON WORLD RENOWNED PAINTINGS?
`
        },
        '18. IRAQ:_ RECLAIMING ANTIQUITIES': {
            'text': `LEDE: The Iraqi government has been working to repatriate thousands of stolen artefacts after they were smuggled out of the country during the U.S. led invasion in 2003. Many of the recently recovered pieces have been put on display at an exhibition in Bagdad. More in this report by Emma Siegel: 
CGS:
1)	FUAD HUSSEIN, Iraqi Foreign Minister
2)	AHMED FAKAK AL-BADRANI Iraqi Culture, Tourism & Antiquities Minister 

SOME HISTORIANS CALL IT THE ‘CRADLE OF CIVILIZATION.’ 
WHILE MARRED WITH CONSTANT WAR AND INSTABILITY, IRAQ BOASTS ANCIENT ARCHAEOLOGICAL SITES WITH ARTEFACTS DATING BACK THOUSANDS OF YEARS.
BUT IN RECENT DECADES, PARTICULARLY AFTER THE 2003 U.S. INVASION, MANY OF THESE ARTEFACTS AND KEY PIECES OF IRAQI HISTORY WERE LOOTED AND SMUGGLED OUT OF THE COUNTRY. 
NOW, THE IRAQI FOREIGN MINISTRY IS WORKING TO GET IT ALL BACK.  
2. SOUNDBITE (Arabic) Fuad Hussein, Iraqi Foreign Minister:
“Today, I hand over to my colleague, His Excellency the Minister of Culture, the outcome of the great joint efforts by the Foreign and Culture ministries and other government agencies, which resulted in the recovery of important Iraqi antiquities which were recovered from abroad.”
MOST OF THE ANCIENT PIECES ON DISPLAY WERE RETURNED BY THE U.S. AFTER THE IRAQI PRIME MINISTER VISITED WASHINGTON IN APRIL. 
8. (SOUNDBITE) (Arabic) IRAQI CULTURE, TOURISM AND ANTIQUITIES MINISTER AHMED FAKAK AL-BADRANI, SAYING:
"It is a great honour on this day to receive a large number of antiquities, including those brought over by the Prime Minister from the United States. And this constitutes the majority of artefacts today, and we will have a special celebration to honour these in the Iraqi museum." 
WHILE 181 SMUGGLED ARTEFACTS WERE GIVEN BACK TO IRAQ, THOUSANDS WENT MISSING IN THE INITIAL LOOTING. UNESCO IS NOW WORKING WITH THE IRAQI AUTHORITIES TO AID IN THE RESTORATION OF IRAQ’S CULTURAL HERITAGE, AND RECOVER MORE PIECES. 
AND THE IRAQI MINISTRY HAS EMPHASIZED ITS COMMITMENT TO TRACKING DOWN THE ANTIQUITIES SCATTERED ACROSS THE GLOBE, DETERMINED TO BRING PRICELESS PARTS OF ITS HISTORY BACK HOME.
`
        },
        '19. QATAR:_ WORLD CUP MUSEUM': {
            'text': `LEDE: With the World Cup just a few months away, Qatar is getting ready to host with a new sports museum for fans coming from all over the world. Our Emma Siegel has the story: 
CGS:
1)	ABDULLA YOUSUF Director of 3-2-1 Qatar Olympic and Sports Museum 
STORY:
QATAR IS GEARING UP TO HOST THE UPCOMING WORLD CUP LATER THIS YEAR BY OPENING A NEW MUSEUM DEDICATED TO SPORTS.
CALLED THE OLYMPIC AND SPORTS MUSEUM, IT’S ONE OF THE LARGEST OF ITS KIND IN THE WORLD AT AROUND 19,000 SQUARE METRES. SPANNING FIVE FLOORS, IT CONTAINS SEVERAL GALLERIES, A GIFT SHOP, A LIBRARY, RESEARCH AREA AND AN AUDITORIUM. 
18. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
"It's really showcasing the country and it is not only for this generation but also for the upcoming generations and they will witness that you know the people of Qatar, the leader of Qatar, they are really taking the sport as an excellent element to showcase the country."
IT WILL BE OPEN TO SPORTS FANS THROUGHOUT THE DURATION OF THE WORLD CUP AND WILL HAVE A SPECIAL EXHIBIT DEDICATED TO THE TOURNAMENT. 
25. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
"We are going to host a big football exhibition during the World Cup and it will last for six months, it will be open from first of October 2022 until 31 March 2023, it is going to be the history of football in the world."
8. (SOUNDBITE) (English) DIRECTOR OF 3-2-1 QATAR OLYMPIC AND SPORTS MUSEUM, ABDULLA YOUSUF AL MULLA, SAYING:
“Everybody is welcome there and in the museum, I believe they will have a good experience and they will have good memories when they go back home."
HOSTED FOR THE FIRST TIME BY A MIDDLE EASTERN COUNTRY, THE WORLD CUP IS SET TO BEGIN IN NOVEMBER 2022.
`
        },
        '20. U.S.:_ GENDER ACCEPTANCE & COURT CASES': {
            'text': `CALIFORNIA LOOKS TO MAKE PARENTAL GENDER ACCEPTANCE OF A CHILD A FACTOR THAT JUDGES WILL CONSIDER WHEN MAKING CUSTODY AND VISITATION DECISIONS. 
 THE NEW BILL, WHICH CLEARED STATE SENATE, WAS INTRODUCED BY LAWMAKER LORI WILSON, A DEMOCRAT WHO HAS A TRANSGENDER SON. 
 WILSON SAYS THAT GENDER AFFIRMATION WOULD BE ONE FACTOR AMONGST OTHERS TO BE CONSIDERED, AND WOULD NOT BE THE ONLY COMPONENT THAT JUDGES MUST WEIGH DURING CUSTODY CASES. 
 EVERY REPUBLICAN IN THE STATE SENATE VOTED AGAINST THE BILL. 
OPPONENTS SAY THAT IT VIOLATES THE RIGHT TO PRIVACY, AS WELL AS FREEDOM OF SPEECH AND RELIGION. SUPPORTERS SAY THAT THE PROPOSED LAW PROTECTS THE HEALTH AND WELL BEING OF TRANSGENDER CHILDREN. 
 IS THIS A PREVENTATIVE MEASURE TO PROTECT TRANSGENDER CHILDREN OR A VIOLATION OF PARENTAL RIGHTS?
WILL THIS PROPOSED LAW AFFECT OTHER STATES?
AND SHOULD GENDER ACCEPTANCE FACTOR INTO CUSTODY CASES?`
        },
        '21. U.S.:_ TRUMP NAMES HIS RUNNING MATE': {
            'text': `LEDE: Meanwhile in America, former U.S. president and current republican Presidential nominee Donald Trump has announced his running mate for the 2024 election - just days after surviving a brazen assassination attempt. But while his pick, JD Vance, has accepted the role, he hasn’t always been a Trump supporter. More in this report by Emma Siegel:
CGS: 

1)	KRISTI NOEM, South Dakota Governor 
2)	 BERNIE MORENO Ohio Delegate & Senate Candidate 
3)	JD Vance, U.S Senator, Republican Vice-President Nominee 
4)	JOE BIDEN U.S. President 

(SOUND UP) JD Vance has the overwhelming majority to be the next Vice President of the United States (CHEERS)

ONLY A FEW DAYS AFTER AN ASSASSINATION ATTEMPT ON FORMER PRESIDENT DONALD TRUMP, HE ANNOUNCED HIS RUNNING MATE FOR VICE PRESIDENT ON HIS PLATFORM ‘TRUTH SOCIAL’ ON THE FIRST DAY OF THE REPUBLICAN NATIONAL CONVENTION.

HE WAS AT THE RNC A SHORT TIME LATER WITH A BANDAGED EAR AND VANCE BY HIS SIDE. 

VANCE, WHO ONCE CALLED TRUMP ‘AMERICA’S HITLER’ HAS BECOME AN ALLY TO THE FORMER PRESIDENT IN RECENT YEARS, AND ULTIMATELY WON TRUMP’S ENDORSEMENT IN THE 2022 OHIO SENATE RACE. WHILE HE ONLY HAS TWO YEARS OF ELECTED EXPERIENCE, VANCE’S SELECTION WAS PRAISED AT THE CONVENTION. 


9171
(SOUNDBITE) (English)SOUTH DAKOTA GOVERNOR KRISTI NOEM SAYING:
"Freedom will make America wealthy again. And with President Trump in the White House, with Senator JD Vance by his side, we will prove it across America."
9144
9. (SOUNDBITE) (English) OHIO DELEGATE AND SENATE CANDIDATE, BERNIE MORENO, STANDING BESIDE VANCE, SAYING:
"To JD Vance America First is not just a slogan, it's his north star. He has followed it every moment of his life and career. He knows what it's like to live in poverty, forgotten by Washington politicians. He is dedicated to ensure that no American is ever forgotten again. Madam Chair, it is a great honor to move that JD Vance be nominated by acclamation by this Republican National Convention as its candidate for the office of Vice President of the United States of America."
WHILE THE 39-YEAR-OLD VANCE WOULD BE THE YOUNGEST VICE PRESIDENT IN AMERICAN HISTORY IF ELECTED, HE HAS MADE A NAME FOR HIMSELF IN JUST A FEW SHORT YEARS, PARTICULARLY FOR HIS 2016 MEMOIR ‘HILLBILLY ELEGY.’ 

HE HAS TRAILBLAZED HIS PATH AS A HARDLINER RIGHT WING REPUBLICAN AND A STAUNCH SUPPORTER OF ISRAEL. 

VANCE’S FOREIGN POLICY STANCE WAS TESTED BY THE BIDEN ADMINISTRATION WITH THE FUNDING PACKAGE FOR ISRAEL COUPLED WITH AID TO UKRAINE - A PACKAGE THAT WAS DELAYED FOR MONTHS BY VANCE AND 14 OTHER REPUBLICANS WHO VOTED AGAINST THE INCLUSION OF AID TO UKRAINE. 

JD VANCE SOT - NOVEMBER 2023 
The United States senate would pass much needed aid to Israel today, at this very moment. Aid the house has already passed, it can go to the president’s signature this afternoon if my democratic colleagues would stand down, but they won’t. Many of them here are gathered to push back against our very common sense proposal to offer support to one of our most important allies as they face an existential threat. And it's important for us to be honest about that fact, to acknowledge that Israel would be getting support from this government tomorrow if our democratic friends didn't stop it today. 

THE YOUNG SENATOR ALSO CRITICISED PRESIDENT BIDEN’S APPROACH TO ISRAEL’S CONDUCT IN THE CURRENT WAR WITH HAMAS, SAYING THAT THE U.S SHOULD NOT BE DICTATING HOW ISRAEL FIGHTS AGAINST THE TERRORIST GROUPS IN THE GAZA STRIP. 

WHEN A REPORTER ASKED BIDEN ABOUT VANCE AS THE VICE PRESIDENT NOMINEE, THE PRESIDENT SEEMINGLY BRUSHED THE QUESTION ASIDE. 

BIDEN SOT 
“He’s a clone of Trump on the issues. So I don’t see any difference.”
AS ONE OF THE LEAST EXPERIENCED VICE PRESIDENT PICKS IN AMERICA’S HISTORY, ONLY TIME WILL TELL HOW JD VANCE FAIRS AS THE U.S. GEARS UP FOR ITS 2024 PRESIDENTIAL ELECTIONS. `
        },
        '22. EUROPE:_ HEATWAVES & FOREST FIRES': {
            'text': `LEDE: The heatwave scorching parts of Europe persists, and forest fires are consuming regions of  France and Spain. There have been over 1000 reported deaths – so far -  and thousands more were forced to flee their homes. i24NEWS’ Emma Siegel has the latest:  
CGS: 
 1.   ABU BAKR, Bank Worker  

HEATWAVES AND FOREST FIRES CONTINUE TO SWEEP ACROSS EUROPE, LEAVING OVER 1000 PEOPLE DEAD  - SO FAR.
IN SOUTHWESTERN FRANCE, TWO HUGE BLAZES HAVE FORCED MORE THAN 16,000 TO FLEE THEIR HOMES. 
THE PEAK OF THE HEAT WAVE IN FRANCE IS FORECAST FOR MONDAY, WHEN TEMPERATURES OF 40 DEGREES CELSIUS  - THAT'S 104 DEGREES FAHRENHEIT - OR HIGHER ARE POSSIBLE.
AND IN THE UK, THE CURRENT NATIONAL TEMPERATURE RECORD IS FROM 2019, AT A SWELTERING 38.7 DEGREES CELSIUS, OR 101 DEGREES FAHRENHEIT. . BUT THE MET OFFICE— UK’S NATIONAL WEATHER SERVICE— IS PREDICTING THERE IS AN 80% CHANCE THAT WILL BE TOPPED — BY WEDNESDAY. 
EXPERTS SAY CLIMATE CHANGE IS RESPONSIBLE FOR MAKING THESE HEAT AVES HOTTER AND LAST LONGER, AND THAT'S A CAUSE FOR CONCERN AMONG THE PUBLIC .   
 32. SOUNDBITE 3 - Abu Bakr, bank worker (English, male)
(On changing his opinion about climate change)
I used to be one of those people that was thinking it’s all just politics and all that. But now I think it’s serious and yes, we definitely need to do something about it. To save our planet and save the generations to come. 
FIREFIGHTERS ACROSS SPAIN WORKED TO DOUSE MORE THAN 30 FIRES ACROSS THE COUNTRY YESTERDAY WHILE MORE THAN 3200 PEOPLE HAVE HAD TO FLEE THE BLAZES.
 ONLOOKERS IN A SOUTHERN TOWN TRYING TO COOL OFF FROM THE SKYROCKETING TEMPERATURES WATCHED AS AUTHORITES MADE EVERY EFFORT TO DOUSE NEARBY FLAMES. 
 FOR SPAIN, THIS IS THE SECOND HEATWAVE OF THE SUMMER, KEEPING THE TEMPERATURE ABOVE 40 DEGREES CELSIUS – 104 DEGFREES FAHRENHEIT IN MANY AREAS.  
AND AS AS CLIMATE CHANGE CONTINUES TO THREATEN WEATHER PATTERNS, TEMPERATURES LIKE THIS MAY PERSIST.  
`
        },
        '23. UK:_ SUNAK VS. TRUSS': {
            'text': `LEDE: The leadership race for the UK conservative party has come down to the final two candidates. Rishi Sunak and Liz Truss are going head-to-head in the bid to become the UK’s next Prime Minister. Emma Siegel has the story: 
CGs:
1.	 RISHI SUNAK, Former British Chancellor of the Exchequer
2.	LIZ TRUSS Foreign Minister & Conservative Leadership Candidate 
IT’S RISHI SUNAK VERSUS LIZ TRUSS IN THE BATTLE TO BECOME THE NEXT PRIME MINISTER OF THE UK. 
AS PRIME MINISTER BORIS JOHNSON FACED HIS LAST QUESTIONS AS PRIME MINISTER IN PARLIAMENT, (SOUND UP) SUNAK AND TRUSS ARE THE FINAL CANDIDATES IN THE CONSERVATIVE PARTY LEADERSHIP RACE. 
BUT WHO ARE THESE TWO CONTENDERS? AND WHAT WILL THEY DO AS PRIME MINISTER? 
SUNAK SERVED AS THE CHANCELLOR OF THE EXCHEQUER, OR  FINANCE MINISTER, UNTIL RESIGNING EARLIER THIS MONTH, HELPING TO TRIGGER JOHNSON’S DOWNFALL. 
HE HELPED TO STEER BRITAIN’S ECONOMY DURING THE PANDEMIC AND IS ADAMANT THAT BRINGING DOWN ESCALATING INFLATION IS BRITAIN’S TOP PRIORITY. 
20. (SOUNDBITE) (English) FORMER BRITISH FINANCE MINISTER, RISHI SUNAK, SAYING:
"My message to the party and the country is simple. I have a plan to steer our economy through these headwinds. We need a return to traditional conservative economic values and that means honesty and responsibility -- not fairy tales.
TRUSS, ON THE OTHER HAND, SEES TAX CUTS AS THE TOP PRIORITY TO HELP BRITAIN’S SPIRALING INFLATION. 
8. (SOUNDBITE) (English) FOREIGN MINISTER AND CONSERVATIVE LEADERSHIP CANDIDATE, LIZ TRUSS, SAYING:
"I have a plan to make Britain a high growth economy over the next 10 years through bold supply side reform. We will cut taxes, helping businesses invest in their future.” 


CURRENTLY SERVING AS BRITAIN’S FOREIGN MINISTER, TRUSS HELPED NEGOTIATE THE DE-ESCALATION OF A LONG STANDING TRADE DISPUTE OVER AIRCRAFT SUBSIDIES, AND HAS PLEDGED TO RAISE DEFENCE SPENDING TO 3% OF GDP BY 2030. 
THE RESULT OF THE FINAL VOTE, WHICH WILL BE CONDUCTED BY CONSERVATIVE PARTY MEMBERS, IS SET TO BE ANNOUNCED BY EARLY SEPTEMBER. `
        },
        '23. WORLD CONFLICT:_ 100 MILLION DISPLACED': {
            'text': `LEDE: Startling new figures show the number of forcibly displaced people around the world has now crossed the grim milestone of 100 million, and the UN says this must serve as a wake-up call to resolve and prevent destructive conflicts. Emma Siegel has the story: 
CGS: 
1.    FILIPPO GRANDI, United Nations High Commissioner for Refugees
 
 A RECORD BREAKING, GRIM MILESTONE. PROPELLED BY THE WAR IN UKRAINE, THE NUMBER OF DISPLACED PEOPLE HAS SOARED TO A STAGGERING 100 MILLION PEOPLE, SAYS THE UN REFUGEE AGENCY.  
FORCED TO FLEE CONFLICT, HUMAN RIGHTS VIOLATIONS AND PERSECUTION WORLDWIDE, THIS IS THE FIRST TIME THE NUMBER HAS TOPPED 100 MILLION.  
THIS JUST DAYS AFTER THE  UNITED NATIONS HIGH COMMISSIONER FOR REFUGEES SPOKE ABOUT DISPLACED UKRAINIANS, DUE TO THE RUSSIAN INVASION.
2. SOUNDBITE (English) Filippo Grandi, United Nations High Commissioner for Refugees:
But, you know, in Ukraine alone, you have 6 million refugees. You have more than 8 million displaced people inside the country. So it's inevitable that, unfortunately, the global displacement crisis is going to... is increasing."
BUT THAT WASN’T THE COMMISSIONER’S ONLY WARNING. 
 3. SOUNDBITE (English) Filippo Grandi, United Nations High Commissioner for Refugees:
There is not just Ukraine, that we should not forget all the rest (...) If you have countries getting into unrest and into political fragility, we may see more displacement. So it is very important as we respond to Ukraine and we will continue to pay adequate attention to crises in Africa, crises in the Middle East, crises in Latin America, crises in Asia and so forth."
 OTHER PLACES MARRED BY VIOLENCE AS OUTLINED BY THE UNHCR INCLUDE AFGHANISTAN—WHERE THE TALIBAN TOOK OVER LAST AUGUST, MYANMAR– WHICH SAW A MILITARY COUP OVERTHROW THE GOVERNMENT IN FEBRUARY 2021,  AS WELL AS ETHIOPIA, NIGERIA AND THE DEMOCRATIC REPUBLIC OF CONGO.  
THE LATEST FIGURES COMBINE REFUGEES, ASYLUM-SEEKERS, AS WELL AS MORE THAN 50 MILLION PEOPLE DISPLACED INSIDE THEIR OWN COUNTRIES.
AND THE UN SAYS THAT WHILE THE INTERNATIONAL RESPONSE TO PEOPLE FLEEING WAR IN UKRAINE HAS BEEN OVERWHELMINGLY POSITIVE, ULTIMATELY, HUMANITARIAN AID IS A PALLIATIVE, AND NOT A CURE TO EVER GROWING PROBLEM.`
        }
    };    
    const url = 'https://emma-siegel.web.app/'
    // const url = 'http://localhost:3000/'
return (
    <div className="bg-gray-50 font-sans">
      <Header />

      <main class="flex flex-col items-center justify-center py-16 min-h-screen">
        <div class="grid grid-cols-1 gap-16 max-w-7xl w-full px-4">
        {Object.keys(videos).map((item) => {
                return (
                    <div key={item} className="grid grid-cols-1 lg:grid-cols-2 gap-8 w-full">
                        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col h-[400px]">
                            <h2 className="text-xl font-normal mb-4">
                                {item.replace('_', ' ').replace('VTR', '').replace('SCRIPT', '').replace(/^\d+\.\s*/, '')}
                            </h2>
                            <div className="flex-grow flex items-center justify-center">
                                <video 
                                    className="w-full h-full object-contain" 
                                    controls
                                >
                                    <source src={`${url}vtrs/${item.replace(':_', '_')}.mp4`} type="video/mp4" />
                                    Your browser does not support HTML video.
                                </video>
                            </div>
                        </div>
                        
                        <div className="bg-white p-6 rounded-lg shadow-md flex flex-col h-[400px]">
                        <h2 className="text-xl font-bold mb-4">Script</h2>
                        <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                            <pre className="whitespace-pre-wrap break-words">
                            {videos[item]['text']}
                            </pre>
                        </div>
                        </div>
                    </div>
                )
            })}
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Home;